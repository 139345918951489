import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Grid, H3, IMG } from "Assets/Material";
import { authUser, emailRegex, formatData, formatDurationToIncludeBrackets, getID, isAnyError, isNumberMessage, isValidString, status, validateRequirements } from "Utils";
import { useRecoilState, toasterGlobal } from "Atom";
import { isValidPhoneNumber } from "libphonenumber-js";
import { sendLeadValidationEmail, useAddProjectLeadService, useEditProjectLeadService } from "Services/ProjectLeadsServices";
import { CreatableAutocomplete, FormButton, LeadStatusTypeSelect, DurationTypeSelect, CityAutocomplete, LanguageAutocomplete } from "Common";
import { addNewFunctionalCapabilityService, addNewUnapprovedIndustries } from "Services/ApprovalPanel";
import { TextInput, Form, IndustryAutocomplete, DateInput, SelectCountryCode } from "Common";
import { searchByFilter, searchTermFilter, sortByFilter, sortOrderFilter, sortOrderListGlobal } from "Atom";
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, CircularProgress } from "Assets/Material";
import Checkbox from '@mui/material/Checkbox';

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

import Box from "@mui/material/Box";
import { TalentManagementStyles as Styles } from "Assets/Styles";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import classes from "./ProjectLead.module.css";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { IconButton, InputAdornment, Modal, Tooltip, Typography, Zoom } from "@mui/material";
import { Loader } from "Common";
import GreenSuccessCheck from "Assets/Images/GreenSuccessCheck.svg";
import { CloseIcon, Delete } from "Assets/Icons";
import { DeleteOutline, HelpOutline } from "@mui/icons-material";
import { CustomDatePicker } from "Common/DateInput/DatePicker";
import ClientAutocomplete from "Common/ClientAutocomplete/ClientLAutoComplete";
const steps = ["Basic Details", "Project Details", "Consultant Requirements"];
const min = 0;
const max = 10;
const AddEditProjectLeads = (props) => {
  // console.log("🚀 ~ AddEditProjectLeads ~ props:", props?.dataEdit?.reason_for_discontinued_or_hold)
  const [skipped, setSkipped] = React.useState(new Set());
  const [error, setError] = useState([{ a: false, b: false, c: false, d: false, e: false, f: false }]);
  const setToaster = useRecoilState(toasterGlobal)[1];
  const setSortBy = useRecoilState(sortByFilter)[1];
  const setSearchBy = useRecoilState(searchByFilter)[1];
  const setSortOrder = useRecoilState(sortOrderFilter)[1];
  const setSearchTerm = useRecoilState(searchTermFilter)[1];
  const setSortOrderList = useRecoilState(sortOrderListGlobal)[1];

  const [onSite, setOnSite] = useState(false);
  const [locationValue, setLocationValue] = useState("");
  const [city, setCity] = useState();
  const [cityErrorMessage, setCityErrorMessage] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [reasonePop, setReasonePop] = useState(false);
  const [resoneTextArea, setResoneTextArea] = useState("");
  const [currentStatus, setCurrentStatus] = useState(0);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [clientInfo, setClientInfo] = useState();
  const [savedLeadId, setSavedLeadId] = useState(null);
  const [showOptionToSendClientEmail, setShowOptionToSendClientEmail] = useState(false);

  const [sendingValidationEmail, setSendingValidationEmail] = useState(false);
  const [showClientSuccessPopup, setShowClientSuccessPopup] = useState(false);
  const [questionnaireField, setQuestionnaireField] = useState([]);
  const [disableClientFields, setdisableClientFields] = useState(false);
  const [disableEditModeClientFields, setdisableEditModeClientFields] = useState(true);
  const [updateStatus, setUpdateStatus] = useState(false);


  const shouldShowOptionToSendClientEmail = () => {
    return authUser()?.user_type_id === 3 && (props?.dataEdit === null || props?.dataEdit?.client_review_status === 'Pending');
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (props.activeStep === 1 && locationValue === "On site" && (!city || city === "0")) {
      setCityErrorMessage(true)
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(props.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(props.activeStep);
    }
    props.handleNext();
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    props.handleBack();
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {

    if (!isStepOptional(props.activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    props.handleNext();
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(props.activeStep);
      return newSkipped;
    });
  };




  let consultantRequirment_data;
  let errorData;

  const [consultantRequirment, setConsultantRequirment] = useState([consultantRequirment_data]);

  const [length, setLength] = useState(0);
  const [questionnaireLength, setQuestionnaireLength] = useState(0);
  const [questionnaireError, setQuestionnaireError] = useState(false);

  const onSuccesss = (data) => { };

  const onError = (error) => {
    console.log({ error });
  };

  // const { isLoading, mutate, isError, error, refetch, isFetching } = useAddProjectLeadService(onSuccess, onError);
  const { mutate: addProjectLead } = useAddProjectLeadService(onSuccesss, onError);
  const { mutate: editProjectLead } = useEditProjectLeadService(onSuccesss, onError);


  const checkQuestions = (questions) => {
    let flag = true;
    questions.map((item, index) => {
      if (item === '') {
        flag = false;
        return;
      }
    })
    return flag;
  }

  const handleIsOpenForApplicationChange = (e, index) => {
    let status = e.target.checked ? 1 : 0;
    consultantRequirment[index].status = status;
    setConsultantRequirment([...consultantRequirment]);
  }

  // const handleClientDetailsCheckBox = (e) => {
  //   setUpdateClientStatus(!updateClientStatus);
  // }



  useEffect(() => {
    if (!checkQuestions(questionnaireField)) {
      setQuestionnaireError(true)
    } else {
      setQuestionnaireError(false);
    }
  }, [questionnaireField])


  useEffect(() => {
    setCity(props?.dataEdit?.location);
  }, [props.dataEdit])


  const handleOpenPoup = (data) => {
    if (data === 2 || data === 6) {
      setReasonePop(true);
      if (data === props?.dataEdit?.lead_status) {
        setResoneTextArea(props?.dataEdit?.reason_for_discontinued_or_hold || "");
      } else {
        setResoneTextArea("");
      }
    }
  };

  const handelOnDataaa = (data) => {
    if (data.value === 2 || data.value === 6) {
      setCurrentStatus(data.value);
      setReasonePop(true);
      if (data.value === props?.dataEdit?.lead_status) {
        setResoneTextArea(props?.dataEdit?.reason_for_discontinued_or_hold || "");
      } /* else {
        setResoneTextArea("");
      } */
    }
  }

  useEffect(() => {
    if (currentStatus !== 2 || currentStatus !== 6) {
      setResoneTextArea('');
    }
    if (currentStatus === 2 || currentStatus === 6) {
      if (currentStatus === props?.dataEdit?.lead_status) {
        setResoneTextArea(props?.dataEdit?.reason_for_discontinued_or_hold);
      } else {
        setResoneTextArea("")
      }
    }
  }, [currentStatus, props?.dataEdit]);

  const onAddProjectLeads = async (values) => {
    // console.log("🚀 ~ onAddProjectLeads ~ values:", values)

    delete values.search_term;

    if (!checkQuestions(questionnaireField)) {
      setQuestionnaireError(true)
      return;
    }

    // if (currentStatus === 2 || currentStatus === 6) {
    //   values.lead_status = resoneTextArea === "" ? props?.dataEdit?.lead_status : values.lead_status
    // }

    if (currentStatus === 2 || currentStatus === 6) {
      if (updateStatus) {
        if (resoneTextArea === "") {
          values.lead_status = props?.dataEdit?.lead_status;
        } else {
          values.lead_status = values.lead_status;
        }
      } else {
        values.lead_status = props?.dataEdit?.lead_status;
      }
    }


    values.consultant_requirements = consultantRequirment;
    consultantRequirment.forEach((requirement) => requirement.location = values.location);
    // if (consultantRequirment[0].number_of_consultant && consultantRequirment[0].capabilities) {
    // }
    if (props.isClientLead) {
      values.isClientLead = true;
    }

    // values.update_client_status = updateClientStatus;

    //do not send search term to the backend, it messes with versioning of the lead
    delete values.search_term;

    if (currentStatus !== props?.dataEdit?.lead_status) {
      // Check if resoneTextArea is empty
      if (!resoneTextArea) {
        // If empty, assign the value from props
        if (currentStatus === 2 || currentStatus === 6) {
          values.reason_for_discontinued_or_hold = props?.dataEdit?.reason_for_discontinued_or_hold;
        } else {
          values.reason_for_discontinued_or_hold = resoneTextArea;
        }
      } else {
        // If not empty, assign the value from resoneTextArea
        if (updateStatus) {
          values.reason_for_discontinued_or_hold = resoneTextArea;
        } else {
          values.reason_for_discontinued_or_hold = props?.dataEdit?.reason_for_discontinued_or_hold;
        }
      }
    } else {
      // If currentStatus is equal to props?.dataEdit?.lead_status
      values.reason_for_discontinued_or_hold = resoneTextArea;
    }

    values.consultant_requirements[length].questionnaire = JSON.stringify(questionnaireField);

    let { errors, isValid } = validateRequirements(values.consultant_requirements);
    if (errors.length > 0 && !isValid) {
      setDisableSaveButton(true);
      setError(errors);
      return;
    }

    setIsFormSubmit(true);

    if (props?.dataEdit) {
      editProjectLead(values, {
        onSuccess: async (data) => {
          let leadId = data.data.lead_id;
          setSavedLeadId(leadId);
          if (values.unapproved_skills.length > 0) {
            const param = {
              request_for: "project_lead",
              lead_id: data.data.lead_id,
              unapproved_capabilities: values.unapproved_skills,
            };
            await addNewFunctionalCapabilityService(param);
          }
          if (values.unapproved_industries.length > 0) {
            const param2 = {
              request_for: "project_lead",
              lead_id: data.data.lead_id,
              unapproved_industries: values.unapproved_industries,
            };
            await addNewUnapprovedIndustries(param2);
          }
          if (shouldShowOptionToSendClientEmail()) {
            setShowOptionToSendClientEmail(true);
          } else {
            closeAddEditPopup(true, data.message);
          }

          setIsFormSubmit(false);
        },
        onError: (error) => {
          console.log("error", error);
        },
      });
    } else {

      addProjectLead(values, {
        onSuccess: async (data) => {
          let leadId = data.data.id;
          setSavedLeadId(leadId);
          setIsFormSubmit(false);
          if (data.status === 200) {
            if (values.unapproved_skills.length > 0) {
              const param = {
                request_for: "project_lead",
                lead_id: data.data.id,
                unapproved_capabilities: values.unapproved_skills,
              };

              await addNewFunctionalCapabilityService(param);
              // if (responseNewFC.meta.ok === 1) closeAddEditPopup(true, data.data.meta.message);
            }
            if (values.unapproved_industries.length > 0) {
              const param = {
                request_for: "project_lead",
                lead_id: data.data.id,
                unapproved_industries: values.unapproved_industries,
              };
              await addNewUnapprovedIndustries(param);
            }
            if (shouldShowOptionToSendClientEmail()) {
              setShowOptionToSendClientEmail(true);
            } else {
              closeAddEditPopup(true, data.message);
            }
          }
        },
        onError: (error) => {
          console.log("error", error);
        },
      });
    }
    setUpdateStatus(false);
    setdisableClientFields(false);
    setClientInfo("");
  };

  const closeAddEditPopup = (isSuccess, message) => {
    props?.onClose();
    setIsFormSubmit(false);
    props?.onSwapperClose();

    let consultantRequirment = [
      {
        capabilities: null,
        number_of_consultant: null,
        experience: null,
        remote_working: true,
        compensation_low: 0,
        compensation_high: 0,
        engagement_preference: "",
        questionnaire: [],
        status: 0
      },
    ];
    setConsultantRequirment(consultantRequirment);
    setLength(0);
    setQuestionnaireLength(0)
    // setActiveStep(0);

    if (isSuccess && props.isClientLead) {
      setShowClientSuccessPopup(true);
    } else {
      if (message) {
        setToaster({ isOpen: true, status: isSuccess ? "success" : "fail", message: message });
      }
    }

  };

  const clearAndCloseModal = () => {
    let consultantRequirment = [
      {
        capabilities: null,
        number_of_consultant: null,
        experience: null,
        remote_working: true,
        compensation_low: 0,
        compensation_high: 0,
        engagement_preference: "",
        questionnaire: [],
        requirement_title: null,
        status: 0
      },
    ];
    setConsultantRequirment(consultantRequirment);

    props?.onClose()
  }

  const sendValidationEmail = async () => {
    setSendingValidationEmail(true);
    let resp = await sendLeadValidationEmail(savedLeadId);
    setSendingValidationEmail(false);
    closeAddEditPopup(resp.status === 200, resp.message);
    if (resp.status === 200) {
      setShowOptionToSendClientEmail(false);
    }
  }

  const resetFilters = () => {
    setSearchBy("1");
    setSearchTerm("");
    setSortBy("id");
    setSortOrder("DESC");
    setSortOrderList([{ id: 1, name: "Default", value: "DESC" }]);
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required.";
    } else if (values.name.trim() === "") {
      errors.name = "Invalid input"
    }
    if (!values.organization || values.organization.trim() === "") {
      errors.organization = "Organization is required.";
    }

    if (!values.email && values.email.trim() === "") {
      errors.email = "Email is required";
    } else if (values.email && !emailRegex.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (values.mobile && isNaN(values.mobile) && values.mobile.trim() !== "") {
      errors.mobile = "Only number is allowed.";
    }
    if (values.duration && !values.duration_type) {
      errors.duration_type = "Provide a duration type";
    }
    if (values.duration_type && !values.duration) {
      errors.duration = "Provide a duration";
    }


    // if (values.country_id && values.country_code && values.mobile) {
    //   let isValidPhone = isValidPhoneNumber(values.mobile, values.country_id);
    //   if (!isValidPhone) {
    //     errors.mobile = "Invalid contact number format";
    //   }
    // }

    if (values.website && values.website.trim() === "") {
      errors.website = "Input valid website link";
    }


    if (values.budget && isNaN(values.budget)) {
      errors.budget = "Only number is allowed.";
    }


    if (values.duration && isNaN(values.duration)) {
      errors.duration = "Only number is allowed.";
    }
    if (values.noOfCon && isNaN(values.noOfCon)) {
      errors.noOfCon = "Only number is allowed.";
    }
    return errors;
  };

  useEffect(() => {
    if (props.dataEdit && props.dataEdit.consultant_requirements && props.dataEdit.consultant_requirements.length > 0) {
      consultantRequirment_data = props.dataEdit.consultant_requirements.map((val) => ({
        capabilities: val.capabilities,
        number_of_consultant: val.inumber_of_consultantd,
        experience: val.experience,
        remote_working: val.remote_working,
        compensation_low: val.compensation_low || 0,
        compensation_high: val.compensation_high || 0,
        engagement_preference: val.engagement_preference,
        id: val.id,
        capabilities: JSON.parse(val.capabilities) && getID(JSON.parse(val.capabilities)),
        capabilitiesAdd: JSON.parse(val.capabilities) && formatData(JSON.parse(val.capabilities)),
        duration_type: val?.duration_type,
        requirement_title: val?.requirement_title,
        status: val?.status,
        languages: JSON.parse(val?.languages) && getID(JSON.parse(val?.languages)),
        languagesAdd: JSON.parse(val?.languages) && formatData(JSON.parse(val?.languages)),
      }));


      if (props.dataEdit.location === "0") {
        setLocationValue("0")
      } else if (props.dataEdit.location !== "") {
        setLocationValue("On site");
        setOnSite(true);
      }

      errorData = props.dataEdit.consultant_requirements.map((val) => ({ a: false, b: false, c: false, d: false }));
    } else {
      consultantRequirment_data = [
        {
          capabilities: null,
          number_of_consultant: 0,
          experience: 0,
          remote_working: true,
          compensation_low: 0,
          compensation_high: 0,
          engagement_preference: "",
          duration_type: "",
          questionnaireField: [],
          requirement_title: null,
          status: 0,
          languages: []
        },
      ];

      errorData = [{ a: false, b: false, c: false, d: false }];
    }

    //we need to keep track of the client info from db in edit mode as well
    if (props.dataEdit) {
      let clientInfo = {
        name: props.dataEdit?.name,
        email: props.dataEdit?.email,
        phone: props.dataEdit?.mobile,
        country_code: props.dataEdit?.country_code,
        organization: props.dataEdit?.organization
      }
      setClientInfo(clientInfo);
    }

    setConsultantRequirment(consultantRequirment_data);
    setError(errorData);
    return () => {
      resetFilters();
      // setActiveStep(0);
    };
  }, [props?.dataEdit]);


  /**
   * move questionaire to different useEffect to avoid re-rendering of the component.
   * this fixes the issue of losing data when moving to next step consultant requirements
   */
  useEffect(() => {
    if (props.dataEdit && props.dataEdit.consultant_requirements.length > 0) {
      const questions = props.dataEdit?.consultant_requirements[questionnaireLength]?.questionnaire;
      if (questions && questions !== null) {
        const temp = JSON.parse(questions);
        setQuestionnaireField(temp)
      }
    } else {
      setQuestionnaireField([]);
    }

  }, [questionnaireLength])

  const handleRichTextChange = (values, content) => {
    values.problem_description = content
  }

  // const compareClientName = (clientName, valuesName) => {
  //   console.log(clientName, valuesName);
  //   if(clientName && valuesName){
  //     return clientName.toLowerCase() === valuesName.toLowerCase();
  //   }
  //   return false;
  // }

  // const compareClientEmail = (clientEmail, valuesEmail) => {
  //   if(clientEmail && valuesEmail){
  //     return clientEmail === valuesEmail;
  //   }
  //   return false;
  // }


  // const compareClientCountryCode = (clientCountryCode, valuesCountryCode) => {
  //   if(clientCountryCode && valuesCountryCode){
  //     return clientCountryCode === Number(valuesCountryCode);
  //   }
  //   return false;
  // }

  // const compareClientPhone = (clientPhone, valuesPhone) => {
  //   if(clientPhone && valuesPhone){
  //     return clientPhone === valuesPhone;
  //   }

  //   return false;
  // }

  // const compareCompanyName = (clientOrganization, valuesOrganization) => {
  //   if(clientOrganization && valuesOrganization){
  //     return clientOrganization.toLowerCase() === valuesOrganization.toLowerCase();
  //   }
  //   return false;
  // }

  // useEffect(() => {

  // }, [])

  const handleSubmitReason = () => {
    if (resoneTextArea.trim().length > 0) {
      setReasonePop(false);
      setUpdateStatus(true)
    }
  }

  if (showOptionToSendClientEmail) {
    return (
      <Modal
        open={true}
      >
        <Box sx={Styles.formWrapper}>
          <div
            style={{
              textAlign: 'center',
              padding: '2rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}>
            <H3 style={{ fontSize: '1.8rem' }}>Lead Saved Successfuly!</H3>
            <div>Do you want to send an email to client for validation?</div>
            <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
              {
                sendingValidationEmail
                  ? <CircularProgress />
                  : (
                    <>
                      <Button
                        type="button"
                        sx={{ padding: "6px 12px", minWidth: "auto" }}
                        size="small"
                        variant="contained"
                        onClick={() => sendValidationEmail()}
                        disabled={sendingValidationEmail}
                      >
                        Send Validation Email
                      </Button>
                      <Button
                        type="button"
                        sx={{ padding: "6px 12px", minWidth: "auto" }}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setShowOptionToSendClientEmail(false);
                          closeAddEditPopup(true);
                          setResoneTextArea("")
                        }}
                      >
                        Close
                      </Button>
                    </>
                  )
              }

            </div>


          </div>

        </Box>

      </Modal>

    )
  }

  // showClientSuccessPopup
  return (
    <div>
      {
        showClientSuccessPopup
          ? (
            <Modal
              open={true}
            >
              <Box sx={{
                padding: '2rem',
                background: '#FFF',
                position: 'absolute',
                bgcolor: "background.paper",
                top: ["0", "0", "50%", "50%", "50%"],
                left: ["0", "0", "50%", "50%", "50%"],
                width: ["100%", "100%", " 80%", " 65%", " 50%"],
                // maxHeight: ["100vh", "100vh", "80vh", "80vh", "80vh"],
                height: ["100vh", "100vh", "auto", "auto", "auto"],
                transform: ["none", "none", "translate(-50%, -50%)", "translate(-50%, -50%)", "translate(-50%, -50%)"]
              }}>
                <CloseIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={() => setShowClientSuccessPopup(false)} />
                <div
                  style={{
                    textAlign: 'center',
                    padding: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                    alignItems: 'center'
                  }}>
                  <IMG src={GreenSuccessCheck} sx={{ width: '50px' }} />
                  <H3 style={{ fontSize: '1.2rem', color: '#000000', fontWeight: '500', padding: '0 2rem' }}>We are happy to confirm that your requirements have been submitted. Your journey with us will be a smooth step-by-step process. Our team will reach out to you soon.</H3>
                  <Box sx={{ width: '100%' }}>
                    {[
                      "Requirement Confirmation by client",
                      "StrategyConnect to Find Consultants",
                      "Client Approval on consultants",
                      "Call Scheduling by StrategyConnect (Client & Consultant)",
                      "Final confirmation by client and consultant",
                      "Project Kick Off"
                    ].map((step_text, k) => {
                      return (
                        <Grid
                          container
                          spacing={0}
                          style={{ margin: "0" }}
                          key={k}
                        >
                          <Grid item xs={3} sm={2} sx={{ margin: 0, padding: 0 }}>
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              position: 'relative',
                              // height: '50px'
                            }}>
                              <div style={{
                                padding: '1rem 1rem 1rem 0',
                                color: '#3B3A97',
                                fontWeight: '600',
                                // fontSize: '0.8rem'
                              }}>STEP {k + 1}</div>
                              <span style={{
                                width: '10px',
                                height: '10px',
                                display: 'inline-block',
                                borderRadius: '50%',
                                zIndex: 2,
                                background: k < 2 ? '#3B3A97' : '#EBEBF4' //background: #EBEBF4;
                              }}>&nbsp;</span>
                              {
                                k !== 0
                                  ? <div style={{ position: 'absolute', right: '4px', top: 0, height: '50%', borderRight: `2px solid ${k < 2 ? '#3B3A97' : '#EBEBF4'}`, zIndex: 1 }}></div>
                                  : ''
                              }
                              {
                                k !== 5
                                  ? <div style={{ position: 'absolute', right: '4px', top: '50%', height: '50%', borderRight: `2px solid ${k < 1 ? '#3B3A97' : '#EBEBF4'}` }}></div>
                                  : ''
                              }

                            </div>

                          </Grid>
                          <Grid item xs={9} sm={10} sx={{ margin: 0, padding: 0 }}>
                            <div style={{ textAlign: 'left', padding: '1rem' }}>{step_text}</div>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Box>
                </div>

              </Box>

            </Modal>
          )
          : ''
      }

      {
        reasonePop ? (
          <Modal open={true}>
            <Box sx={{
              padding: '2rem',
              background: '#FFF',
              position: 'absolute',
              bgcolor: "background.paper",
              top: ["0", "0", "45%", "45%", "45%"],
              left: ["0", "0", "50%", "50%", "50%"],
              width: ["50%", "50%", " 45%", " 40%", " 35%"],
              height: ["90vh", "90vh", "auto", "auto", "auto"],
              transform: ["none", "none", "translate(-50%, -50%)", "translate(-50%, -50%)", "translate(-50%, -50%)"]
            }}>
              <CloseIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={() => setReasonePop(false)} />
              <div
                style={{
                  textAlign: 'center',
                  padding: '0',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2rem',
                  alignItems: 'center'
                }}>
                <H3 style={{ fontSize: '1.2rem', color: '#000000', fontWeight: '500', padding: '0 2rem' }}>
                  Please provide a reason for {currentStatus === 2 ? "Project Discontinued" : "On Hold "} status.
                </H3>

                {/* TextArea for Reason */}
                <TextField
                  id="reason-text"
                  // label="Reason"
                  multiline
                  rows={4}
                  value={resoneTextArea}
                  variant="outlined"
                  sx={{ width: '100%', maxWidth: '500px' }}
                  onChange={(e) => {
                    setResoneTextArea(e.target.value);
                  }}
                />

                {/* Submit Button */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitReason} // Assuming handleSubmitReason handles API submission
                  sx={{ width: '200px' }}
                >
                  Ok
                </Button>
              </div>
            </Box>
          </Modal>
        ) : ''
      }

      <Form onOpen={props?.onOpen} onClose={clearAndCloseModal} title={props.isClientLead ? "Review & Approve project brief " : "Project Leads"}>
        <Stepper activeStep={props.activeStep} variant="dots" style={{ marginButtom: "2rem" }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <React.Fragment>
          {/* props?.dataEdit == null
            ? true
            : props?.dataEdit &&
              props?.onOpen && */}
          {
            <Formik
              initialValues={{
                name: props?.dataEdit ? props?.dataEdit.name : "", //
                organization: props?.dataEdit ? props?.dataEdit.organization : "", //
                mobile: props?.dataEdit ? props?.dataEdit.mobile : "", //
                country_code: props?.dataEdit ? props?.dataEdit.country_code : "", //
                country_id: "",
                email: props?.dataEdit ? props?.dataEdit.email : "", //
                // estimated_start_date: props?.dataEdit ? props?.dataEdit.estimated_start_date : new Date(), //
                estimated_start_date: props?.dataEdit ? props?.dataEdit.estimated_start_date : "", //
                created_by: props?.dataEdit ? props?.dataEdit.created_by : authUser()?.user_id, //
                modified_by: authUser()?.user_id,
                lead_status: props?.dataEdit ? props?.dataEdit.lead_status : 3, //
                budget_min: props?.dataEdit ? props?.dataEdit.budget_min : null, //
                budget_max: props?.dataEdit ? props?.dataEdit.budget_max : null, //
                industries: [], //
                website: props?.dataEdit ? props?.dataEdit.website : "", //
                problem_description: props?.dataEdit ? props?.dataEdit.problem_description : "", //
                project_title: props?.dataEdit ? props?.dataEdit?.project_title : "",
                duration: props?.dataEdit ? props?.dataEdit.duration : null, //
                duration_type: props?.dataEdit ? props?.dataEdit.duration_type : "", //
                special_request: props?.dataEdit ? props?.dataEdit.special_request : "", //
                location: props?.dataEdit ? props?.dataEdit.location : "",
                client_id: props?.dataEdit ? props?.dataEdit.client_id : null,
                // budget: props?.dataEdit ? props?.dataEdit.budget : null,
                industries: props?.dataEdit ? props?.dataEdit?.industries && JSON.parse(props?.dataEdit.industries).map((i) => i.id) : [],
                // capabilities: props?.dataEdit?.fc_data ? JSON.parse(props?.dataEdit?.fc_data).map((i) => i.id) : [],
                unapproved_skills: [],
                // updated_by: props.dataEdit:? props.data
                question: "",
                unapproved_industries: [],
                lead_id: props?.dataEdit ? props?.dataEdit?.lead_id : null,
                compensation_high: props?.dataEdit ? props?.dataEdit?.compensation_high : null,
                compensation_low: props?.dataEdit ? props?.dataEdit?.compensation_low : null,
                search_term: "",
                // deadline_date: props?.dataEdit ? props?.dataEdit.deadline_date : new Date(),
                deadline_date: props?.dataEdit ? props?.dataEdit.deadline_date : "",
              }}
              validate={(values) => validateForm(values)}
              onSubmit={(values) => onAddProjectLeads(values)}
            >
              {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <form onSubmit={handleSubmit}>
                  {props.activeStep === 0 && (
                    <Grid
                      container
                      spacing={2}
                      style={{ marginTop: "1rem" }}
                      title={""}
                    >
                      <Grid item xs={12} sm={12}>
                        {authUser()?.user_type_id === 3 &&
                          <ClientAutocomplete
                            label="Search client by name"
                            limit={10}
                            value={{ label: values.search_term, value: values.search_term }}
                            type="name"
                            initialValues={values.name}
                            getValue={(client) => {
                              setFieldValue("name", client.name, true);
                              setFieldValue("email", client.email, true);
                              setFieldValue("mobile", client.phone, true);
                              setFieldValue("country_code", client?.country_code ? "+" + client?.country_code : "", true);
                              setFieldValue("organization", client?.organization, true);
                              setFieldValue("client_id", client?.client_id, true);
                              setFieldValue("search_term", client?.name, true);

                              // setClientInfo({name: client.name, email: client.email, phone: client.phone, country_code: client.country_code, organization: client.organization});

                              //this is a fix i found on the internet to keep the setFieldValue in sync with the formik state
                              setTimeout(() => setFieldTouched('name', true));
                            }}
                            editMode={props?.dataEdit}
                            disableClientFields={(value) => setdisableClientFields(value)}
                            disableEditModeClientFields={(value) => setdisableEditModeClientFields(value)}
                            placeholder="Search client by name"
                          // onError={errors.name}
                          />
                        }
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <ClientAutocomplete 
                          label="Name *"
                          limit={10}
                          value={{label: values.name, value: values.name}}
                          type="name"
                          initialValues={values.name}
                          getValue = {(client) => {
                            
                            setFieldValue("name", client.name, true);
                            setFieldValue("email", client.email, true);
                            setFieldValue("mobile", client.phone, true);
                            setFieldValue("country_code", client?.country_code ? "+" + client?.country_code : "", true);
                            setFieldValue("organization", client?.organization, true);
                            setFieldValue("client_id", client?.client_id, true);

                            //this is a fix i found on the internet to keep the setFieldValue in sync with the formik state
                            setTimeout(() => setFieldTouched('name', true));
                          }}
                          placeholder="Search client by name" 
                          onError={errors.name}
                        /> */}

                        <TextInput
                          label="Name *"
                          name="name"
                          disabled={props.dataEdit ? disableEditModeClientFields : disableClientFields}
                          onChange={(e) => {
                            handleChange(e);
                            // let status = compareClientName(clientInfo?.name, e.target.value);
                            // console.log("status", status)
                            // if (status === false) {
                            //   console.log("toggle1")
                            //   setShowSaveClientCheckBox(true);
                            // } else {
                            //   setShowSaveClientCheckBox(false);
                            //   console.log("toggle2")
                            // }
                          }}
                          onBlur={handleBlur}
                          value={values.name}
                          onError={errors.name && touched.name && errors.name}
                        />

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          label="Email ID *"
                          name="email"
                          onChange={(e) => {
                            handleChange(e);
                            // let status = compareClientEmail(clientInfo?.email, e.target.value);
                            // if (status === false) {
                            //   setShowSaveClientCheckBox(true);
                            // } else {
                            //   setShowSaveClientCheckBox(false);
                            // }
                          }}
                          onBlur={handleBlur}
                          disabled={props.dataEdit ? disableEditModeClientFields : disableClientFields}
                          value={values.email}
                          onError={errors.email && touched.email && errors.email}
                        />


                        {/* 
                        <ClientAutocomplete 
                          label="Email ID *"
                          limit={10}
                          type="email"
                          initialValues={values.email}
                          getValue = {(client) => {
                            
                            setFieldValue("name", client.name, true);
                            setFieldValue("email", client.email, true);
                            setFieldValue("mobile", client.phone, true);
                            setFieldValue("country_code", client?.country_code ? "+" + client?.country_code : "", true);
                            setFieldValue("organization", client?.organization, true);
                            setFieldValue("client_id", client?.client_id, true);

                            //this is a fix i found on the internet to keep the setFieldValue in sync with the formik state
                            setTimeout(() => setFieldTouched('email', true));
                          }}
                          placeholder="Search client by email" 
                          onError={errors.name}
                          value={{label: values.email, value: values.email}}
                        /> */}

                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <SelectCountryCode
                          requiredForm={false}
                          // isRequired={isCountryCodeError}
                          initialValue={values.country_code && values?.country_code.split("+")[1]}
                          getValue={(country) => {
                            values.country_code = `+${country.phone}`;
                            values.country_id = country.code;


                            // console.log("hello")

                            // let status = compareClientCountryCode(clientInfo?.country_code, country.phone);
                            // if (status === false) {
                            //   setShowSaveClientCheckBox(true);
                            // } else {
                            //   setShowSaveClientCheckBox(false);
                            // }
                            // setIsCountryCodeError(false);
                          }}
                          disabled={props.dataEdit ? disableEditModeClientFields : disableClientFields}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextInput
                          type="text"
                          label="Contact Number"
                          name="mobile"
                          onChange={(e) => {
                            handleChange(e);
                            // let status = compareClientPhone(clientInfo?.phone, e.target.value);
                            // if (status === false) {
                            //   setShowSaveClientCheckBox(true);
                            // } else {
                            //   setShowSaveClientCheckBox(false);
                            // }
                          }}
                          disabled={props.dataEdit ? disableEditModeClientFields : disableClientFields}
                          onBlur={handleBlur}
                          value={values.mobile}
                          onError={errors.mobile && touched.mobile && errors.mobile}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          type="text"
                          label="Organization *"
                          name="organization"
                          onChange={(e) => {
                            handleChange(e);
                            // let status = compareCompanyName(clientInfo?.organization, e.target.value);
                            // if (status === false) {
                            //   setShowSaveClientCheckBox(true);
                            // } else {
                            //   setShowSaveClientCheckBox(false);
                            // }
                          }}
                          disabled={props.dataEdit ? disableEditModeClientFields : disableClientFields}
                          onBlur={handleBlur}
                          value={values.organization}
                          onError={errors.organization && touched.organization && errors.organization}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ paddingTop: "0!important" }}>
                        <IndustryAutocomplete
                          initialValues={
                            props?.dataEdit?.industries &&
                            JSON.parse(props?.dataEdit?.industries) &&
                            formatData(JSON.parse(props?.dataEdit?.industries))
                          }
                          getValue={(value) => {
                            values.industries = value.industries;
                            values.unapproved_industries = value.unapproved_industries;
                          }}
                        />
                      </Grid>

                      {/* add Lead status */}
                      <Grid item xs={12} sm={6}>
                        <LeadStatusTypeSelect
                          value={values.lead_status}
                          label="Lead Status *"
                          name="lead_status"
                          onChange={(e) => {
                            setCurrentStatus(e.target.value);
                            handleChange(e);
                            handleOpenPoup(e.target.value);
                          }}
                          handelOnDataaa={handelOnDataaa}
                          onBlur={handleBlur}
                          onError={errors.lead_status && touched.lead_status && errors.lead_status}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={12}>
                        {showSaveClientCheckBox && values.client_id && 
                        <>
                          <FormControlLabel 
                            label="update client details as above"
                            control={
                              <Checkbox checked={updateClientStatus} onChange={(e) => handleClientDetailsCheckBox(e)} inputProps={{'aria-label': 'update client details as above'}} /> 
                              // <Checkbox checked={consultantRequirment[index].status} onChange={(e) => handleIsOpenForApplicationChange(e, index)} inputProps={{'aria-label': 'Open for Application'}} /> 
                            }
                          />
                          <Tooltip size="sm" 
                            title="Disable to apply the change to the client details on this lead only. If enabled, the client in the system will be updated with the new details. This will also update the client details on all leads associated with this client." 
                            placement="right-start">
                            <IconButton>
                              <HelpOutline style={{ fontSize: '17px', color: 'gray', marginLeft: "-20px" }} />
                            </IconButton>
                          </Tooltip>
                        </>
                        }


                      </Grid> */}
                      { authUser().user_type_id === 3 && 
                        <>
                          <Grid item xs={12} sm={3}>
                            <TextInput
                              type="text"
                              label="budget min"
                              name="budget_min"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.budget_min}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <TextInput
                              type="text"
                              label="budget max"
                              name="budget_max"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.budget_max}
                            />
                          </Grid>

                        </>
                      }

                      <Grid item xs={12} sm={12}>
                        <TextInput
                          type="text"
                          label="Website"
                          name="website"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.website}
                          onError={errors.website && touched.website && errors.website}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {props.activeStep === 1 && (
                    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                      <Grid item xs={12} sm={12}>
                        <ReactQuill value={values.problem_description} onChange={value => handleRichTextChange(values, value)} theme="snow" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="duration"
                          label="Duration"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.duration}
                          onError={errors.duration && touched.duration && errors.duration}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <DurationTypeSelect
                          name="duration_type"
                          label="Duration Type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.duration_type && formatDurationToIncludeBrackets(values.duration_type)}
                          onError={errors.duration_type && touched.duration_type && errors.duration_type}
                        />
                        {errors.duration_type && <span style={{ fontSize: '0.75rem', color: "#ff1744", margin: "4px 0 0 14px" }}>{errors.duration_type}</span>}
                      </Grid>



                      <Grid item xs={12} md={4}>
                        <CustomDatePicker
                          // sx={{width: "50%"}}
                          label="Start Date"
                          initialValues={values?.estimated_start_date ? new Date(values?.estimated_start_date) : new Date()}
                          getDate={(date) => (values.estimated_start_date = new Date(date))}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <CustomDatePicker
                          // sx={{width: "50%"}}
                          label="Submission Deadline"
                          initialValues={values?.deadline_date ? new Date(values?.deadline_date) : new Date()}
                          getDate={(date) => (values.deadline_date = new Date(date))}
                        />
                      </Grid>



                      <Grid item >
                        <FormControl
                          fullWidth
                          component="fieldset"
                          sx={Styles.engagementPreferenceWrapper}
                        >
                          <FormLabel
                            sx={{ ...Styles.engagementPreferenceFormLabel }}
                          >
                            Location Preference
                          </FormLabel>
                          <RadioGroup
                            row
                            fullWidth
                            style={{
                              ...Styles.radioButtonWrapper,
                              ...{ justifyContent: "start", gap: "1rem" },
                            }}
                            name="commitment_preference_id"
                            value={locationValue}
                            onChange={(e) => {
                              const { value } = e.target;
                              if (value === "On site") {
                                values.location = props?.dataEdit?.location;
                                setLocationValue("On site")
                                setOnSite(true)
                              } else {
                                values.location = value;
                                setLocationValue("0")
                                setOnSite(false)
                              }
                            }}
                          >
                            <FormControlLabel
                              value={"0"}
                              label="Remote Only"
                              control={<Radio size="small" />}
                              sx={Styles.radioButtonLabel}
                            />
                            <FormControlLabel
                              value={"On site"}
                              label="On Site Possible"
                              control={<Radio size="small" />}
                              sx={Styles.radioButtonLabel}
                            />
                          </RadioGroup>

                        </FormControl>
                      </Grid>

                      {onSite &&
                        <Grid item xs={12} md={4}>
                          <CityAutocomplete initialValues={values.location === "0" ? "" : values.location}
                            label="City"
                            getValue={(value) => {
                              values.location = value;
                              setCity(value)
                              setCityErrorMessage(false);
                            }}
                            placeholder="Search Cities"
                          />
                          {cityErrorMessage && <div style={{ color: 'red' }}>Please select a city</div>}
                        </Grid>
                      }
                    </Grid>
                  )}
                  {props.activeStep === 2 && (
                    <Grid
                      container
                      style={{
                        marginTop: "1rem",
                        transition: "30s",
                        transition: "width 3s",
                        transitionTimingFunction: "linear",
                      }}
                    >
                      {consultantRequirment &&
                        consultantRequirment.map((res, index) => {
                          if (index === length) {
                            return (
                              <div
                                key={index}
                                style={{
                                  background: "aliceblue",
                                  padding: "12px",
                                  transition: "30s",
                                  transitionTimingFunction: "linear",
                                }}
                              >
                                <Zoom
                                  in={true}
                                  // style={{ transformOrigin: "0 0 0" }}
                                  // {...{ timeout: 1000 }}
                                  style={{ transitionDelay: "500ms" }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} sx={{ paddingTop: "0!important" }}>
                                      <TextInput
                                        type="text"
                                        label="Requirement title *"
                                        name="requirement_title"
                                        required
                                        onChange={(e) => {
                                          consultantRequirment[index].requirement_title = e.target.value
                                          setConsultantRequirment([...consultantRequirment]);
                                          let requirement = e.target.value;

                                          if (requirement == "" || requirement == null || requirement == undefined || requirement.trim() == "") {
                                            error[index].e = "Requirement title is required";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].e = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                        }}
                                        value={consultantRequirment[index].requirement_title}
                                        onBlur={handleBlur}
                                        onError={error[index] && error[index].e}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12} sx={{ paddingTop: "0!important" }}>
                                      <CreatableAutocomplete
                                        bg={true}
                                        isSelectOnly={true}
                                        isLabel={true}
                                        initialValues={
                                          consultantRequirment[index].capabilitiesAdd
                                        }
                                        getValue={(value) => {
                                          consultantRequirment[index].capabilities = value.capabilities;
                                          consultantRequirment[index].capabilitiesAdd = value.capabilitiesAdd;
                                          setConsultantRequirment([...consultantRequirment]);

                                          let capabilities = value.capabilities;
                                          if (capabilities == "" || capabilities == null || capabilities == undefined && capabilities.trim() == "") {
                                            error[index].f = "Capabilities are required";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].f = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                        }}
                                      />
                                      {error[index].f ? <p style={{ fontSize: '0.75rem', color: "#ff1744", margin: "4px 0 0 14px" }}>{error[index].f}</p> : ""}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextInput
                                        type="text"
                                        label="No. of Consultants *"
                                        name="noOfCon"
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                          let k = isValidString(e.target.value);
                                          let number_of_consultant = e.target.value;

                                          consultantRequirment[index].number_of_consultant = number_of_consultant
                                          setConsultantRequirment([...consultantRequirment]);

                                          if (k == false) {
                                            error[index].a = "Only number is allowed.";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else if (number_of_consultant == undefined || number_of_consultant == null || number_of_consultant.trim() == "" || number_of_consultant == "" || number_of_consultant == 0) {
                                            error[index].a = "Number of consultants is required and cannot be zero";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].a = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }

                                        }}
                                        onError={error[index] && error[index].a}
                                        onBlur={handleBlur}
                                        value={consultantRequirment[index].number_of_consultant}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextInput
                                        // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                        type="text"
                                        label="Years of Experience"
                                        name="experience"
                                        onChange={(e) => {
                                          let k = isValidString(e.target.value);
                                          // if (k == undefined || k == "" || k == null) {
                                          //   consultantRequirment[index].experience = e.target.value;
                                          //   setConsultantRequirment([...consultantRequirment]);

                                          //   // setError([...(error[index].b = false)]);
                                          // } else {
                                          //   consultantRequirment[index].experience = e.target.value;
                                          //   setConsultantRequirment([...consultantRequirment]);
                                          //   // setError([...(error[index].b = true)]);
                                          // }

                                          let experience = e.target.value;
                                          consultantRequirment[index].experience = experience;
                                          setConsultantRequirment([...consultantRequirment]);


                                          if (k == false) {
                                            error[index].b = "Only number is allowed.";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else if (experience.trim() == "") {
                                            error[index].b = "Enter valid input";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].b = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                        }}
                                        onError={error[index] && error[index].b && "Only number is allowed."}
                                        // onChange={(e) => {
                                        //   console.log(typeof e.target.value);
                                        //   consultantRequirment[index].experience = e.target.value;
                                        //   setConsultantRequirment([...consultantRequirment]);
                                        // }}
                                        // onError={errors.experience && touched.experience && errors.experience}
                                        onBlur={handleBlur}
                                        value={consultantRequirment[index].experience}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                      <FormControl
                                        fullWidth
                                        component="fieldset"
                                        sx={Styles.engagementPreferenceWrapper}
                                      >
                                        <FormLabel
                                          sx={{ ...Styles.engagementPreferenceFormLabel, background: "aliceblue" }}
                                        >
                                          Engagement Preference
                                        </FormLabel>
                                        <RadioGroup
                                          row
                                          fullWidth
                                          style={{
                                            ...Styles.radioButtonWrapper,
                                            ...{ justifyContent: "start", gap: "6px" },
                                          }}
                                          name="commitment_preference_id"
                                          onChange={(e) => {
                                            consultantRequirment[index].engagement_preference = e.target.value;
                                            setConsultantRequirment([...consultantRequirment]);
                                          }}
                                          value={consultantRequirment[index].engagement_preference}
                                        >
                                          <FormControlLabel
                                            value={"Full Time"}
                                            label="Full Time"
                                            control={<Radio size="small" />}
                                            sx={Styles.radioButtonLabel}
                                          />
                                          <FormControlLabel
                                            value={"Part Time"}
                                            label="Part Time"
                                            control={<Radio size="small" />}
                                            sx={Styles.radioButtonLabel}
                                          />
                                          <FormControlLabel
                                            value={"Both"}
                                            label="Both"
                                            control={<Radio size="small" />}
                                            sx={Styles.radioButtonLabel}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={2.5}>
                                      <TextInput
                                        type="text"
                                        label="Compensation Range"
                                        name="organization"
                                        placeholder="Min"
                                        onChange={(e) => {
                                          let k = isValidString(e.target.value);
                                          let compensation_low = e.target.value;
                                          consultantRequirment[index].compensation_low = compensation_low;
                                          setConsultantRequirment([...consultantRequirment])

                                          if (k == false) {
                                            error[index].c = "Only number is allowed.";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else if (compensation_low.trim() == "") {
                                            error[index].c = "Enter valid input";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].c = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }


                                        }}
                                        onError={error[index] && error[index].c && "Only number is allowed."}
                                        // onChange={(e) => {
                                        //   consultantRequirment[index].compensation_low = e.target.value;
                                        //   setConsultantRequirment([...consultantRequirment]);
                                        // }}
                                        onBlur={handleBlur}
                                        value={consultantRequirment[index].compensation_low}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={1}
                                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    >
                                      To
                                    </Grid>
                                    <Grid item xs={6} sm={2.5}>
                                      <TextInput
                                        type="number"
                                        // label="Compensation"
                                        name="second"
                                        placeholder="Max"
                                        onChange={(e) => {
                                          let k = isValidString(e.target.value);
                                          let compensation_high = e.target.value;
                                          consultantRequirment[index].compensation_high = compensation_high;
                                          setConsultantRequirment([...consultantRequirment]);

                                          if (k == false) {
                                            error[index].d = "Only number is allowed.";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else if (compensation_high.trim() == "") {
                                            error[index].d = "Enter valid input";
                                            setError([...error]);
                                            setDisableSaveButton(true)
                                          } else {
                                            error[index].d = false;
                                            setError([...error]);
                                            setDisableSaveButton(false)
                                          }
                                        }}
                                        onError={error[index] && error[index].d && "Only number is allowed."}
                                        // onChange={(e) => {
                                        //   consultantRequirment[index].compensation_high = e.target.value;
                                        //   setConsultantRequirment([...consultantRequirment]);
                                        // }}
                                        value={consultantRequirment[index].compensation_high}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                      <LanguageAutocomplete
                                        initialValues={consultantRequirment[index].languagesAdd}
                                        isLabel={true}
                                        getValue={(value) => {
                                          consultantRequirment[index].languages = value.languages;
                                          consultantRequirment[index].languagesAdd = value.languagesAdd;
                                          setConsultantRequirment([...consultantRequirment]);
                                        }
                                        }
                                      />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6}>
                                      <DurationTypeSelect
                                        name="duration_type"
                                        label="Duration Type"
                                        onChange={(e) => {
                                          consultantRequirment[index].duration_type = e.target.value;
                                          setConsultantRequirment([...consultantRequirment]);
                                        }}
                                        onBlur={handleBlur}
                                        value={consultantRequirment[index].duration_type}
                                        onError={errors.duration_type && touched.duration_type && errors.duration_type}
                                        type= "Lead edit"
                                      />
                                    </Grid> */}

                                    <Grid item xs={12} sm={6}>
                                      <FormControlLabel
                                        label="Open for Application"
                                        control={
                                          <Checkbox checked={consultantRequirment[index].status} onChange={(e) => handleIsOpenForApplicationChange(e, index)} inputProps={{ 'aria-label': 'Open for Application' }} />
                                        }
                                      />

                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography>Add Questionnaire</Typography>
                                        <button
                                          type="button"
                                          className={classes.addButton}
                                          onClick={() => {
                                            let fields = [...questionnaireField];
                                            fields.push("");
                                            setQuestionnaireField(fields)
                                          }}
                                        >
                                          <AddCircleOutlinedIcon />
                                        </button>
                                      </div>
                                      {questionnaireField && questionnaireField.map((item, index) => (
                                        <TextInput
                                          label={"question " + (index + 1)}
                                          name="questionnaire"
                                          fullWidth
                                          multiline
                                          margin="dense"
                                          variant="outlined"
                                          rows={2}
                                          InputLabelProps={{ shrink: true }}
                                          onChange={(e) => {
                                            questionnaireField[index] = e.target.value;
                                            setQuestionnaireField([...questionnaireField]);
                                          }}
                                          value={questionnaireField[index]}
                                          onBlur={handleBlur}
                                          autoFocus={true}
                                          onError={errors.questionnaire && touched.questionnaire && errors.questionnaire}
                                          adornment={{
                                            endAdornment:
                                              <InputAdornment position="end">
                                                <IconButton onClick={() => {
                                                  questionnaireField.splice(index, 1);
                                                  setQuestionnaireField([...questionnaireField])
                                                }}>
                                                  <DeleteOutline />
                                                </IconButton>
                                              </InputAdornment>,
                                          }}

                                        />
                                      )
                                      )}
                                      {questionnaireError && <Typography style={{ color: "red" }}>Please add question</Typography>}

                                    </Grid>
                                  </Grid>
                                </Zoom>
                              </div>
                            );
                          }
                        })}

                      <div className={classes.grid}>
                        <div className={classes.flexCenter}>
                          <button
                            type="button"
                            style={
                              consultantRequirment[0].capabilitiesAdd &&
                                consultantRequirment[0].capabilitiesAdd.length > 0 &&
                                consultantRequirment[0].number_of_consultant >= 0
                                ? {}
                                : { color: "gray", pointerEvents: "none" }
                            }
                            className={classes.addButton}
                            onClick={() => {
                              let addData = [...consultantRequirment];
                              addData.push({
                                capabilities: null,
                                number_of_consultant: null,
                                experience: null,
                                remote_working: true,
                                compensation_low: 0,
                                compensation_high: 0,
                                engagement_preference: "",
                                status: 0,
                                languages: null
                              });
                              setConsultantRequirment(addData);
                              setQuestionnaireField([])
                              let errorSet = [...error];
                              errorSet.push({ a: false, b: false, c: false, d: false, e: false, f: false });
                              setError(errorSet);
                              setDisableSaveButton(true)
                              setLength((pre) => pre + 1);
                            }}
                          >
                            <AddCircleOutlinedIcon
                              style={
                                consultantRequirment[0].capabilitiesAdd &&
                                  consultantRequirment[0].capabilitiesAdd.length > 0 &&
                                  consultantRequirment[0].number_of_consultant > 0
                                  ? {}
                                  : { color: "gray", pointerEvents: "none" }
                              }
                            />
                          </button>
                          Add Consultant Requirement
                        </div>
                        {consultantRequirment.length !== 1 && (
                          <div className={classes.flexCenter} style={{ justifyContent: "end" }}>
                            <KeyboardArrowLeft
                              style={length === 0 ? { color: "gray", pointerEvents: "none" } : {}}
                              // disabled={length === 0}

                              onClick={() => {
                                setLength((pre) => pre - 1);
                                setQuestionnaireLength((pre) => pre - 1)
                              }}
                            />
                            {length + 1} of {consultantRequirment.length}
                            <KeyboardArrowRight
                              style={
                                length + 1 === consultantRequirment.length || error[length].e || error[length].a || error[length].f || error[length].b || error[length].c || error[length].d
                                  ? { color: "gray", pointerEvents: "none" }
                                  : {}
                              }
                              onClick={() => {
                                setLength((pre) => pre + 1);
                                setQuestionnaireLength((pre) => pre + 1)
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Special Request"
                          name="special_request"
                          fullWidth
                          multiline
                          margin="dense"
                          variant="outlined"
                          rows={2}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.special_request}
                          onError={errors.special_request && touched.special_request && errors.special_request}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2, pb: window.innerWidth === 900 ? 0 : 10 }}>
                    <Button
                      type="button"
                      sx={{ padding: "0 6px", minWidth: "auto", mr: 1 }}
                      size="small"
                      variant="contained"
                      disabled={props.activeStep === 0}
                      onClick={handleBack}
                    >
                      <KeyboardDoubleArrowLeftIcon sx={{ color: "white" }} />
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {isStepOptional(props.activeStep) && !onSite && (!values.duration && !values.duration_type) && (
                      <Button
                        type="button"
                        size="small"
                        sx={{ padding: "0 6px", minWidth: "auto", mr: 1 }}
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {props.activeStep === 2 ? (
                      <FormButton
                        size="small"
                        sx={{ padding: "0 6px", minWidth: "auto" }}
                        disabled={isFormSubmit}
                        isDisabled={isFormSubmit || disableSaveButton}
                        label={props?.isEdit ? (!props.isClientLead ? "Save" : "Save & Approve") : "Add"}
                      />
                    ) : (

                      <Button
                        type="button"
                        sx={{ padding: "0 6px", minWidth: "auto" }}
                        size="small"
                        variant="contained"
                        onClick={handleNext}
                        disabled={(props.dataEdit ? !(isValid && errors) : !(isValid && errors && dirty))}
                        // dirty
                        style={props.activeStep === 2 ? { pointerEvents: "none", background: "gray" } : {}}
                      >
                        <KeyboardDoubleArrowRightIcon sx={{ color: "white" }} />
                      </Button>
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          }
        </React.Fragment>
      </Form>
    </div>
  );
};

export default AddEditProjectLeads;
